import React from "react";
import space from "../images/space.gif"

function EtherealGallery(props){
    const {setGalleryOpen, imageMap, unitMap} = props

    const [orbitMap,setOrbitMap] = React.useState([])
    const [title, setTitle] = React.useState("")

    React.useEffect(()=>{
        let image = document.getElementById("imageMap")
        image.onload = handleMapping
        window.addEventListener('resize',handleMapping)

        function handleMapping(){
            let width = image.width
            let resizedMap = unitMap.map(element=>{
                return {
                    "ethereal": element.ethereal,
                    "coordinates": element.coordinates.map(x=>x*width).join(',')
                }
            })
            setOrbitMap(resizedMap)
        }

    },[unitMap])

    return(
        <React.Fragment>
            <div className="player">
                <div className="overlay"
                     style={{background:`url(${space}) repeat top center`}}
                     onClick={(e) => {
                         if(e.target.getAttribute("id") === "closeBtn")
                            setGalleryOpen(false)
                     }}
                >
                    <div className="player-content">
                        <center className="lead" style={{fontSize:"1.4em"}}>{title}</center>
                        <div className="center-screen">
                        <img id="imageMap" src={imageMap}  width="100%" alt="" useMap="#image-map" />
                            <map name="image-map">
                                {orbitMap.map((element,i)=>{
                                    return <area
                                        role="button" shape="circle" alt="..."
                                        title={element.ethereal}
                                        coords={element.coordinates}
                                        onMouseEnter={()=>setTitle(element.ethereal)}
                                        onMouseOut={()=>setTitle("")}
                                    />
                                })}
                            </map>

                        </div>
                    </div>
                    <div className="close disabled" tabIndex="0" id="closeBtn" role="button">close</div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EtherealGallery