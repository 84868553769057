import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css'
import './App.css'
import './ghost-box.css'
import './Player.css'
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Nav from "./components/Nav.js";
import Home from './components/Home.js';
import PageNotFound from "./components/PageNotFound";
import {StorageProvider} from "./components/StorageContext"

function App() {
  return (
      <StorageProvider>
          <Router>
              <Nav/>
              <Switch>
                  <Route exact path="/" component={Home} />
                  <Route component={PageNotFound} />
              </Switch>
          </Router>
      </StorageProvider>
  );
}

export default App;
