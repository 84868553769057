import React from "react";
import Modal from "react-modal";
import axios from "axios";
import dateformat from "dateformat"
import {StorageContext} from "./StorageContext";
import {Link} from "react-router-dom";
import logo from '../images/logo.png'
import Player from "./Player";
import EtherealGallery from "./EtherealGallery";
import Configs from "./Configs";

const logStyles = {
    content: {
        height: '70%',
        width: '70%',
        margin: 'auto',
        marginTop: '60px',
        padding: '2em',
    },
};

function Home(){
    document.title = "Proxima"

    const {setCurrentPage} = React.useContext(StorageContext)

    const [visits, setVisits] = React.useState(0)

    const [isLogOpen, setLogOpen] = React.useState(false)
    const [logData, setLogData] = React.useState("")
    const [logTitle, setLogTitle] = React.useState("")

    const [logs, setLogs] = React.useState([])
    const [replaced, setReplaced] = React.useState([])
    const [ethereal, setEthereal] = React.useState([])

    const [isPlayerOpen, setPlayerOpen] = React.useState(false)
    const [mediaSrc, setMediaSrc] = React.useState("#")
    const [playing, setPlaying] = React.useState(false)

    const [galleryOpen, setGalleryOpen] = React.useState(false)
    const [orbitMap,setOrbitMap] = React.useState([])
    const [imageMap,setImageMap] = React.useState("")

    React.useEffect(()=>{
        setCurrentPage('home')
    },[setCurrentPage])

    React.useEffect(()=>{
       axios.get(`https://api.countapi.xyz/hit/${Configs.NAMESPACE}/${Configs.COUNT_API_KEY}`)
           .then(response=> setVisits(response.data.value))
           .catch(error=>console.log(error))
    },[])

    React.useEffect(()=>{
        document.onkeydown = (event)=>{
            if(event.key===" " && isPlayerOpen) {
                event.preventDefault()
                setPlaying(!playing)
            }
        }
    })

    React.useEffect(()=>{
        axios.get("https://0xdigitalart.heptadecane.xyz/apis/logs.json")
            .then(response=> setLogs(response.data))
            .catch(error=>console.log(error))
    },[])

    React.useEffect(()=>{
        axios.get("https://0xdigitalart.heptadecane.xyz/apis/heptaheads.json")
            .then(response=> setReplaced(response.data))
            .catch(error=>console.log(error))
    },[])

    React.useEffect(()=>{
        axios.get("https://0xdigitalart.heptadecane.xyz/apis/ethereal-orbits.json")
            .then(response=> setEthereal(response.data))
            .catch(error=>console.log(error))
    },[])


    const openPlayer = (mediaSrc)=>{
        setMediaSrc(mediaSrc)
        setPlaying(true)
        setPlayerOpen(true)
    }

    const closePlayer = ()=>{
        setMediaSrc("#")
        setPlaying(false)
        setPlayerOpen(false)
    }

    return(
        <React.Fragment>

            <Modal
                isOpen={isLogOpen}
                style={logStyles}
                onRequestClose={()=>setLogOpen(false)}
            >
                <div className="log-title">{logTitle}</div>
                <p>{logData}</p>
            </Modal>

            {isPlayerOpen && <Player url={mediaSrc} playing={playing} setPlaying={setPlaying} closePlayer={closePlayer}/>}
            {galleryOpen && <EtherealGallery setGalleryOpen={setGalleryOpen} imageMap={imageMap} unitMap={orbitMap}/>}

            <div className="container">
                <h1>A weblog for Proxima simulation</h1>
                <hr className="separator"/>

                <h2>logs</h2>
                <div className="row" style={{align:'center'}}>
                    {logs.map((element,i)=>{
                        return(<div className="col-auto">
                            <div className="card" style={{width:'16rem'}}
                                 onClick={()=>{
                                     setLogOpen(true)
                                     setLogTitle(element.title)
                                     setLogData(element.content)
                                 }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title">{"Log #"+i+": "}</h5>
                                    <div className="log-title">{element.title}</div>
                                    <div className="separator"/>
                                    <div className="card-footer bg-transparent">
                                        <small className=" card-text text-muted">
                                            {dateformat(element.date,"dddd, mmmm dS, yyyy")}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>

                <hr className="separator"/>

                <h2>Replaced HeptaHeads</h2>
                <div className="row">
                    {replaced.map((element,i)=>
                        <div className="col-auto">
                            <div className="card" style={{width:'16rem'}}>
                                <div className="card-header bg-transparent">
                                    {element.uniqueId}
                                    {element.gender==='female'?
                                        <i
                                            className="fa fa-venus"
                                            style={{float: "right", fontWeight:'bold', color:'#df73ff'}}
                                        />
                                        :
                                        <i
                                            className="fa fa-mars"
                                            style={{float: "right", fontWeight:'bold', color:'#6d63e7'}}
                                        />
                                    }
                                </div>
                                <img src={element.avatar} className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">{element.assignedName}</h5>

                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item"/>
                                        <li className="list-group-item"/>
                                    </ul>
                                    {element.openseaLink==="#"?
                                        <a href={element.openseaLink} className="btn btn-primary d-grid gap-2 disabled" target="_blank" rel="noreferrer">
                                            Stay tuned !
                                        </a>
                                        :
                                        <a href={element.openseaLink} className="btn btn-primary d-grid gap-2" target="_blank" rel="noreferrer">
                                            View on Opensea
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                </div>

                <hr className="separator"/>

                <h2>Ethereal Orbits</h2>
                <div className="row">
                    {ethereal.map((element, i)=>
                        <div className="col-auto">
                            <div className="card" style={{width:'16rem'}}>
                                <div className="card-header bg-transparent">
                                    {element.imageMap !== "#"?
                                        <span role="button"
                                              onClick={()=> {
                                                  setGalleryOpen(true)
                                                  setOrbitMap(element.orbitMap)
                                                  setImageMap(element.imageMap)
                                              }}
                                        >
                                            {element.name}
                                        </span>
                                        :
                                        <span>{element.name}</span>
                                    }
                                </div>
                                <div className="content"
                                     onClick={()=> {
                                         if(element.mediaSrc !== "#")
                                            openPlayer(element.mediaSrc)
                                     }}
                                >
                                    <div className="content-overlay"/>
                                    <img className="content-image" src={element.preview} alt="..."/>
                                    <div className="content-details fadeIn-bottom">
                                        <h3 className="content-title">
                                            {element.mediaSrc==="#" && <i className="fa fa-circle-o-notch fa-lg"/>}
                                            {element.mediaSrc!=="#" && <i className="fa fa-play fa-lg"/>}
                                        </h3>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {element.openseaLink==="#"?
                                        <a href={element.openseaLink} className="btn btn-primary d-grid gap-2 disabled" target="_blank" rel="noreferrer">
                                            Stay tuned !
                                        </a>
                                        :
                                        <a href={element.openseaLink} className="btn btn-primary d-grid gap-2" target="_blank" rel="noreferrer">
                                            View on Opensea
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                </div>

                <hr className="separator"/>

                <footer className="footer_area">
                    <div className="footer_logo">
                        <Link to="/">
                            <img src={logo} height="20px" alt=""/>
                        </Link>
                    </div>

                    <div>
                        <button className="count btn btn-outline-primary" disabled={true}>
                            Visits: {visits}
                        </button>
                    </div>
                    <p className="footer-text">
                        Copyright ©{new Date().getFullYear()} All rights reserved by <span className="blank_link">Heptadecane</span>
                    </p>
                </footer>
            </div>
        </React.Fragment>
    )
}
export default Home