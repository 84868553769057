import React from "react";
import ReactPlayer from "react-player/lazy";

function Player(props){
    const {url, playing, setPlaying, closePlayer} = props;
    const [loading, setLoading] = React.useState(true)
    return(
        <React.Fragment>
            <div className="player">
                <div className="overlay"
                     onClick={(e) => {
                         if(e.target instanceof HTMLVideoElement)
                            setPlaying(!playing)
                         else closePlayer()
                     }}
                >
                    <div className="player-content">
                        {loading && <div className="center-screen">
                            <i className="fa fa-spinner fa-pulse fa-3x" style={{color:'#fff'}}/>
                        </div>}
                        <ReactPlayer
                            id="player"
                            loop={true}
                            playing={playing}
                            url={url}
                            width="100%"
                            height="100%"
                            onReady={() => {
                                setLoading(false)
                                setPlaying(true)
                            }}
                        />
                    </div>
                    <div className="close disabled" tabIndex="0" role="button">close</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Player