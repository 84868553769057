import React from "react";
import logo from '../images/logo.png'
import openseaLogo from '../images/opensea.svg'
import {Link} from "react-router-dom";
import {StorageContext} from "./StorageContext";

function Nav(){
    const {currentPage} = React.useContext(StorageContext)

    return(
        <nav className="navbar navbar-expand-lg navbar-light fixed-top">
            <div className="container">
                <Link className="navbar-brand logo_h nav-logo" to="/"><img src={logo} height="20px" alt=""/></Link>
                {currentPage!=="404"?
                    <div>
                        <a className="navbar-brand logo_h nav-logo" href="https://opensea.io/heptadecane?tab=created_collections" target="_blank" rel="noreferrer">
                            <img src={openseaLogo} alt="" height={'30px'}/>
                        </a>
                    </div>
                    :
                    <div/>
                }
            </div>
        </nav>
)
}

export default Nav